@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
* {
  font-family: 'Onest', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

option {
  color: black;
}
